import { default as indexMf5jkORaCRMeta } from "/app/frontend/src/pages/index.vue?macro=true";
import { default as accountseqNMoZ6TJPMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/accounts.vue?macro=true";
import { default as _91alertId_93gelLoUXV8fMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/[alertId].vue?macro=true";
import { default as indexxNICOx2CwpMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/index.vue?macro=true";
import { default as unsubscribe4BZIPoVdAEMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/unsubscribe.vue?macro=true";
import { default as indexKiA3ILPgUoMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/index.vue?macro=true";
import { default as reportsqCyN4DTq2gMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/reports.vue?macro=true";
import { default as index6tsFOSXP2dMeta } from "/app/frontend/src/pages/login/index.vue?macro=true";
import { default as reauthnwza5tMdRrMeta } from "/app/frontend/src/pages/login/reauth.vue?macro=true";
import { default as _91domainId_93vZGhICPcaVMeta } from "/app/frontend/src/pages/lookup/[domainId].vue?macro=true";
import { default as indexIlyOjkh9UqMeta } from "/app/frontend/src/pages/lookup/index.vue?macro=true";
import { default as apitokensNzrX0HbFb7Meta } from "/app/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexqsOtAAd0dxMeta } from "/app/frontend/src/pages/users/self/index.vue?macro=true";
import { default as securityFhHzVi7ffMMeta } from "/app/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfAUDD3dmxkeMeta } from "/app/frontend/src/pages/users/self.vue?macro=true";
import { default as set_45password4emSIKEGWCMeta } from "/app/frontend/src/pages/users/set-password.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId-accounts",
    path: "/leakprofiles/:leakprofileId()/accounts",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/accounts.vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId-alerts-alertId",
    path: "/leakprofiles/:leakprofileId()/alerts/:alertId()",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/[alertId].vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId-alerts",
    path: "/leakprofiles/:leakprofileId()/alerts",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/index.vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId-alerts-unsubscribe",
    path: "/leakprofiles/:leakprofileId()/alerts/unsubscribe",
    meta: unsubscribe4BZIPoVdAEMeta || {},
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId",
    path: "/leakprofiles/:leakprofileId()",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/index.vue").then(m => m.default || m)
  },
  {
    name: "leakprofiles-leakprofileId-reports",
    path: "/leakprofiles/:leakprofileId()/reports",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/reports.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: index6tsFOSXP2dMeta || {},
    component: () => import("/app/frontend/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauthnwza5tMdRrMeta || {},
    component: () => import("/app/frontend/src/pages/login/reauth.vue").then(m => m.default || m)
  },
  {
    name: "lookup-domainId",
    path: "/lookup/:domainId()",
    component: () => import("/app/frontend/src/pages/lookup/[domainId].vue").then(m => m.default || m)
  },
  {
    name: "lookup",
    path: "/lookup",
    component: () => import("/app/frontend/src/pages/lookup/index.vue").then(m => m.default || m)
  },
  {
    name: selfAUDD3dmxkeMeta?.name,
    path: "/users/self",
    component: () => import("/app/frontend/src/pages/users/self.vue").then(m => m.default || m),
    children: [
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/frontend/src/pages/users/self/apitokens.vue").then(m => m.default || m)
  },
  {
    name: "users-self",
    path: "",
    component: () => import("/app/frontend/src/pages/users/self/index.vue").then(m => m.default || m)
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/frontend/src/pages/users/self/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-set-password",
    path: "/users/set-password",
    meta: set_45password4emSIKEGWCMeta || {},
    component: () => import("/app/frontend/src/pages/users/set-password.vue").then(m => m.default || m)
  }
]