<template>
  <v-app :theme="theme">
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </v-app>
</template>

<script setup lang="ts">
const localSettings = useLocalSettings();

const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)');
const systemThemeIsDark = ref<boolean>(colorSchemeQueryList.matches);
colorSchemeQueryList.addEventListener('change', (event) => {
  systemThemeIsDark.value = event.matches;
});

const theme = computed(() => {
  let theme = localSettings.theme;
  if (!theme || !['light', 'dark'].includes(theme)) {
    // Use system theme
    if (systemThemeIsDark.value) {
      theme = 'dark';
    } else {
      theme = 'light';
    }
  }
  return theme;
});
</script>
