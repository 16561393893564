export const useMainMenuStore = defineStore('mainMenu', {
  state: () => ({
    leakprofileFetcher: useSearchableCursorPaginationFetcher<LeakProfile>({
      baseURL: '/api/v1/leakprofiles/',
      query: {
        ordering: 'name,created',
      },
    }),
    leakProfile: null as LeakProfile|null,
  }),
});
